var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('indexHeader',{attrs:{"title":_vm.$t('manufacturingModels.ManufacturingModels'),"description":_vm.$t('manufacturingModels.FromHereYouCanControlManufacturingModels')}}),_c('indexTable',{attrs:{"searchText":_vm.$t('manufacturingModels.SearchForAModel'),"emptyTableText":_vm.$t('manufacturingModels.ThereAreNoModels'),"localData":true,"buttonRole":_vm.$user.admin || _vm.$user.role.manufacturing_models_add,"emptyTableSubText":_vm.$t('manufacturingModels.TryAddingItemsToTheTableAndTryAgain'),"cloumns":[
          {
            column: 'name',
            title: _vm.$t('manufacturingModels.FormName'),
            type: 'mainLink',
            sort: true,
          },
          {
            column: 'day',
            title: _vm.$t('manufacturingModels.FormTime'),
            type: 'text',
            sort: true,
            after: _vm.$t('manufacturingModels.day')
          },
          {
            column: 'expected_cost',
            title: _vm.$t('manufacturingModels.expectedCost'),
            type: 'text',
            sort: true
          },
          {
            column: 'expected_price',
            title: _vm.$t('manufacturingModels.expectedPrice'),
            type: 'text',
            sort: true
          },
          {
            column: 'status',
            title: _vm.$t('manufacturingModels.Status'),
            type: 'status',
            sort: true,
            values: [
              { title: _vm.$t('manufacturingModels.ItIsOpen'), value: 0, color: 'dark' },
              { title: _vm.$t('manufacturingModels.InProgress'), value: 1, color: 'primary' },
              { title: _vm.$t('manufacturingModels.Very'), value: 2, color: 'success' },
              { title: _vm.$t('manufacturingModels.canceled'), value: 3, color: 'danger' } ],
          },
          {
            column: 'options',
            title: _vm.$t('manufacturingModels.Settings'),
            type: 'options',
            options: [
              { name: 'show' },
              { name: 'edit', role: _vm.$user.admin || _vm.$user.role.manufacturing_models_edit },
              { name: 'delete', role: _vm.$user.admin || _vm.$user.role.manufacturing_models_delete } ],
          } ],"deleteText":{
          attention: _vm.$t('allerts.Attention'),
          areYouReallySureToDeleteTheItem:
            _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
          close: _vm.$t('allerts.close'),
          confirm: _vm.$t('allerts.confirm'),
          loading: _vm.$t('allerts.loading'),
        }}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }